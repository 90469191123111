import { Flex } from 'components/Flex';
import { FC } from 'react';
import { Button, Dialog, styled } from 'shamrock-clover-ui';

const DialogContainer = styled.div`
  [role='document'] {
    margin-bottom: 36px; // TODO: This will need to be updated when the capacity delete dialog is implemented.  This styling will work for lane delete and lane/capacity delete
  }
`;

const DialogDescriptionText = styled.p<{ lineHeight: boolean}>`
  line-height: ${({ lineHeight }) => (lineHeight ? '20px' : '30px')};
  padding-top: 15px;
`;

const DeleteLaneCapacityDialog: FC<{
  showDialog: boolean;
  dialogTitle: string;
  dialogDescription: string;
  deletingCapacity: boolean;
  handleCloseDialog: () => void;
  handleDelete: () => void;
}> = ({ showDialog, dialogTitle, dialogDescription, deletingCapacity, handleCloseDialog, handleDelete}) => {
  return (
    <DialogContainer>
      <Dialog
        open={showDialog}
        title={dialogTitle}
        content={<DialogDescriptionText lineHeight={deletingCapacity}>{dialogDescription}</DialogDescriptionText>} // TODO: will need to be updated for 'capacity' delete dialog
        width="532px"
        height="295px"
        closeOnBackdropClick={false}
        actions={
          <Flex justifyContent="space-between" width="100%">
            <Button
              width="74px"
              buttonStyle="outlined"
              onClick={handleCloseDialog}
            >
              Cancel
            </Button>
            <Button
              width="72px"
              buttonStyle="solid"
              color="red"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Flex>
        }
      />
    </DialogContainer>
  );
};

export default DeleteLaneCapacityDialog;
