import { SmrTable } from 'shamrock-clover-ui';
import CapacityScoreChip from './CapacityScoreChip';
import { useContext, useState } from 'react';
import { SortOrder, Column, Row } from 'components/cloverTable/TableTypes';
import { CarrierCapacityContext } from '../context/CarrierCapacityContext';
import {
  CompanyText,
  StyledCheckmark,
  TableContainer,
} from '../CarrierCapacityStyles';
import { ResultText, StyledTextContainer } from './Drawer/DrawerStyles';
import CompanyInfoDrawer from './Drawer/CompanyInfoDrawer';

const SearchCapacityResults = () => {
  const {
    carrierCapacitySearchResultsState,
    lanesAndCapacity,
    openDetails,
    setOpenDetails,
    setCarrierIndexTaken,
  } = useContext(CarrierCapacityContext);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
  const [sortedColumnIndex, setSortedColumnIndex] = useState(-1);
  const [sortDirection, setSortDirection] = useState<SortOrder>('desc');

  const handleRowClick = (index: number) => {
    setOpenDetails(true);
    setSelectedRowIndex(index);
    setCarrierIndexTaken(index);
  };

  const tableRows: Row[] = lanesAndCapacity.map((data, index: number) => {
    const { carrier } = data;
    return {
      cells: [
        {
          content: <CapacityScoreChip score={carrier.score} />,
          value: carrier.score,
        },
        {
          content: <CompanyText>{carrier.companyName}</CompanyText>,
          value: carrier.companyName,
        },
        {
          content: carrier.oneYearLoadCount.toString() || '0',
          value: carrier.oneYearLoadCount || 0,
        },
        {
          content: carrier.headquartersStateAbbr,
          value: carrier.headquartersStateAbbr,
        },
        {
          content: carrier.trucks.toString(),
          value: carrier.trucks,
        },
        {
          content: (
            <>
              {carrier.isRtsProUser ? (
                <StyledCheckmark icon="checkCircleGreen" color="gray3" />
              ) : (
                <></>
              )}
            </>
          ),
          value: carrier.isRtsProUser.toString(),
        },
        {
          content: carrier.mcleodCarrierId,
          value: carrier.mcleodCarrierId,
        },
        {
          content: (
            <>
              MC: {carrier.mcNumber}
              <br />
              DOT: {carrier.dotNumber}
            </>
          ),
          value: `${carrier.mcNumber} ${carrier.dotNumber}`,
        },
        {
          content: carrier.contactName,
          value: carrier.contactName,
        },
        {
          content: (
            <>
              {carrier.contactPhone}
              <br />
              {carrier.contactEmail}
            </>
          ),
          value: `${carrier.contactPhone} ${carrier.contactEmail}`,
        },
      ],
      onClick: () => handleRowClick(index),
    };
  });

  const [sortedTableRows, setSortedTableRows] = useState<Row[]>(tableRows);
  const tableColumns: Column[] = [
    {
      key: 'score',
      label: 'Score',
      isSortable: true,
      width: 7,
    },
    {
      key: 'company',
      label: 'Company',
      isSortable: true,
      width: 12,
    },
    {
      key: 'loadHistory',
      label: 'Load History',
      isSortable: true,
      alignment: 'center',
      width: 9.3,
    },
    {
      key: 'hq',
      label: 'HQ',
      isSortable: false,
      alignment: 'left',
      width: 5.1,
    },
    {
      key: 'trucks',
      label: 'Trucks',
      isSortable: true,
      alignment: 'right',
      width: 6,
    },
    {
      key: 'rtsprouser',
      label: 'RTS Pro User',
      isSortable: true,
      alignment: 'center',
      width: 9.6,
    },
    {
      key: 'mcleodId',
      label: 'McLeod ID',
      isSortable: false,
      width: 9,
    },
    {
      key: 'mc/dot',
      label: 'MC/DOT',
      isSortable: false,
      width: 11,
    },
    {
      key: 'name',
      label: 'Name',
      isSortable: false,
      width: 15,
    },
    {
      key: 'contact',
      label: 'Contact',
      isSortable: false,
      width: 16,
    },
  ];

  const handleSort = (columnIndex?: number, direction?: SortOrder) => {
    if (columnIndex !== undefined && direction) {
      setSortedColumnIndex(columnIndex);
      setSortDirection(direction);
    }

    const sortedRows = [...sortedTableRows].sort((a, b) => {
      const aValue =
        columnIndex !== undefined ? a.cells[columnIndex].value : '';
      const bValue =
        columnIndex !== undefined ? b.cells[columnIndex].value : '';
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      return 0;
    });
    setSortedTableRows(sortedRows);
  };

  return (
    <>
      {lanesAndCapacity.length !== 0 && (
        <CompanyInfoDrawer
          isOpen={openDetails}
          setIsOpen={setOpenDetails}
          selectedRowIndex={selectedRowIndex}
          searchCapacity={true}
        />
      )}

      <StyledTextContainer>
        <ResultText>{lanesAndCapacity.length} Matching Carriers</ResultText>
      </StyledTextContainer>
      <TableContainer>
        <SmrTable
          loading={carrierCapacitySearchResultsState === 'isLoading'}
          columns={tableColumns}
          rows={tableRows}
          footerOptions={{
            page: 1,
            rowsPerPageSelector: true,
            totalRowsDisplay: 'rows',
            customRowsPerPageOptions: [10, 25, 50, 75, 100],
          }}
          sortingOptions={{
            onSort: handleSort,
            sortedColumnIndex: sortedColumnIndex,
            sortDirection: sortDirection,
          }}
        />
      </TableContainer>
    </>
  );
};

export default SearchCapacityResults;
