/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import { DrawerContentNavigation } from './DrawerStyles';
import { CarrierProfileTab } from 'features/carrierCapacity/CapacityTypes';

const DrawerSubHeader: FC<{
  setTabClicked: (tab: CarrierProfileTab) => void;
}> = ({ setTabClicked }) => {
  const handleDetailsClicked = () => {
    setTabClicked('Details');
  };

  const handleLoadHistoryClicked = () => {
    setTabClicked('Load History');
  };

  const tabs = [
    {
      title: 'Details',
      onClick: handleDetailsClicked,
    },
    {
      title: 'Load History',
      onClick: handleLoadHistoryClicked,
    },
  ];

  return <DrawerContentNavigation tabs={tabs} separatorWidth={0} />;
};

export default DrawerSubHeader;
