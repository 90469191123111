import { createContext, FC, useContext, PropsWithChildren } from 'react';
import { CarrierCapacityContext } from '../context/CarrierCapacityContext';
import DeleteLaneCapacityDialog from '../components/DeleteLaneCapacityDialog';

export interface DialogContexType {
  handleShowDialog: () => void;
  showDialog: boolean;
}

export const DeleteDialogContext = createContext<DialogContexType>(
  {} as DialogContexType,
);

export const DeleteDialogWrapper: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const {
    isEdited,
    showDialog,
    laneHasCapacity,
    deletingCapacity,
    handleShowDialog,
    handleCloseDialog,
  } = useContext(CarrierCapacityContext);

  let dialogDescription: string = '';
  let title: string = '';
  
  if (isEdited && laneHasCapacity) {
    dialogDescription = 'This cannot be recovered.';
    title = 'Delete lane and capacity?';
  }

  if (isEdited && !laneHasCapacity) {
    dialogDescription = 'This cannot be recovered.';
    title = 'Delete lane?';
  }

  if (laneHasCapacity && deletingCapacity) {
    dialogDescription =
      'Your capacity preferences will be removed from this lane.  You can add them again anytime.';
    title = 'Delete capacity?';
  }
  
  // function here simply to show what type of delete is being done - might be better to move this to the context when the delete is wired up
  const handleDelete = () => {
    if (title === 'Delete lane and capacity?') {
      console.log('Delete lane and capacity');
    }

    if (title === 'Delete lane?') {
      console.log('Delete lane');
    }

    if (title === 'Delete capacity?') {
      console.log('Delete capacity');
    }
  };

  return (
    <DeleteDialogContext.Provider value={{ showDialog, handleShowDialog }}>
      <>
        <DeleteLaneCapacityDialog
          showDialog={showDialog}
          dialogTitle={title}
          dialogDescription={dialogDescription}
          handleCloseDialog={handleCloseDialog}
          handleDelete={handleDelete}
          deletingCapacity={deletingCapacity}
        />
        {children}
      </>
    </DeleteDialogContext.Provider>
  );
};

export const useDeleteDialogContext = () => {
  const context = useContext(DeleteDialogContext);
  if (context === undefined) {
    throw new Error(
      'useDeleteDialogContext must be used within an DeleteDialogContext.Provider',
    );
  }
  return context;
};
