import usStates from './usStates.json';
import canadaStates from './canadaStates.json';

export const getStateOrProvinceAbbreviation = (stateOrProvince: string) => {
  const usState = usStates.find(
    (state) => state.name === stateOrProvince,
  )?.abbreviation;
  const caProvince = canadaStates.find(
    (state) => state.name === stateOrProvince,
  )?.abbreviation;
  return usState ?? caProvince;
};
