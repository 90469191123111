import { FC, useContext, useState } from 'react';
import { Drawer } from 'shamrock-clover-ui';
import CarrierProfileDrawerHeader from './CarrierProfileDrawerHeader';
import { CarrierCapacityContext } from 'features/carrierCapacity/context/CarrierCapacityContext';
import { ContentContainer } from './DrawerStyles';
import DrawerSubHeader from './DrawerSubheader';
import {
  CarrierProfileTab,
  CompanyInfoDrawerProps,
} from 'features/carrierCapacity/CapacityTypes';
import CompanyDetails from './CompanyDetails';
import DetailsTable from './DetailsTable';
import AddEditLanesDrawer from './AddEditLanesDrawer';
import { CarrierLoadHistory } from './CarrierLoadHistory';
import { Snackbar } from 'shamrock-clover-ui';

const CompanyInfoDrawer: FC<CompanyInfoDrawerProps> = ({
  isOpen,
  setIsOpen,
  selectedRowIndex,
  searchCapacity,
}) => {
  const {
    lanesAndCapacity,
    openAddLane,
    openEditLane,
    carriers,
    snackbarNotification,
    handleAddLaneDrawer,
    handleEditLaneDrawer,
    handleOnCloseAddLane,
    handleOnCloseEditLane,
  } = useContext(CarrierCapacityContext);

  const [drawerTabClicked, setDrawerTabClicked] =
    useState<CarrierProfileTab>('Details');

  const companyInfo =
    lanesAndCapacity[selectedRowIndex] || carriers[selectedRowIndex];

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        headerSeparatorType="none"
        width={'1161'}
        overlay={true}
        drawerHeaderContent={
          <CarrierProfileDrawerHeader
            companyInfo={companyInfo}
            {...(searchCapacity && { searchCapacity: true })}
          />
        }
      >
        <ContentContainer>
          <DrawerSubHeader setTabClicked={setDrawerTabClicked} />
          {drawerTabClicked === 'Details' && (
            <>
              <CompanyDetails data={companyInfo} />
              <DetailsTable
                data={companyInfo}
                actionHandler={handleAddLaneDrawer}
                editHandler={(index, source) => {
                  handleEditLaneDrawer(companyInfo.lanes[index], index, source);
                }}
              />
            </>
          )}
          {drawerTabClicked === 'Load History' && <CarrierLoadHistory />}
          <Snackbar
            message={snackbarNotification.message}
            open={snackbarNotification.open}
            variant="info"
          />
        </ContentContainer>
      </Drawer>
      <AddEditLanesDrawer
        open={openAddLane}
        onClose={handleOnCloseAddLane}
        companyInfo={companyInfo}
      />
      <AddEditLanesDrawer
        open={openEditLane}
        editLane={true}
        onClose={handleOnCloseEditLane}
        companyInfo={companyInfo}
      />
    </>
  );
};

export default CompanyInfoDrawer;
