import { Grid } from '@material-ui/core';
import { Flex } from 'components/Flex';
import { Chip, Button, Icon, styled, FilterInput } from 'shamrock-clover-ui';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[10]};
  height: calc(100vh - 98px);
`;

export const CapacitySearchContainer = styled.div`
  padding: 16px 64px;
  max-width: 1648px;
`;

export const GridItem = styled(Grid)`
  padding: 8px;
`;

export const StyledChip = styled(Chip)<{ backgroundColor: string }>`
  font-size: 16px;
  color: white;
  font-weight: 800;
  max-height: 12px;
  max-width: 34px;
  min-width: 34px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor && backgroundColor};
`;

export const StyledResetButton = styled(Button)`
  margin-top: 18px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 400;
`;

export const StyledCheckmark = styled(Icon)<{ paddingLeft?: string }>`
  width: 16px;
  height: 16px;
  padding-left: 72px;
`;

export const ViewText = styled.div`
  font-size: 48px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray[50]};
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: start;
  height: 68px;
  line-height: 58px;
  width: auto;
  margin-top: 24px;
`;

export const TableContainer = styled.div<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : '0px')};
  width: 100%;
  max-height: 565px;
  overflow-y: scroll;
  border-top: 1px solid #cccccc;
  thead {
    position: sticky;
    top: 0;
    z-index: 2;
    th {
      border-top: hidden;
      span {
        font-size: 16px;
      }
    }
  }
  tbody {
    td {
      font-size: 16px;
    }
  }
  div:not(thead div) {
    position: sticky;
    bottom: 0;
  }
`;

export const CompanyText = styled.div`
  color: #0091ea;
`;

export const ToolbarContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  height: 40px;
  align-items: center;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 8px 16px;
  gap: 24px;
`;

export const StyledInput = styled(FilterInput)`
  border: none;
  font-family: Proxima Nova;
  font-size: ${({ theme }) => theme.font.size.xsmall};
  color: #333333;
  ::placeholder {
    color: ${({ theme }) => theme.colors.gray[40]};
    font: 14px;
    font-weight: 400;
  }
`;
