import { FC, useContext } from 'react';
import {
  AddLaneAndCapacityContent,
  AddLaneForm,
  ContentContainer,
  CustomDrawer,
  InputsGroup,
  InputsTitles,
  SelectInputContainer,
} from './DrawerStyles';
import { AddCapacityForm, CapacityForm } from '../CapacityForm';
import AddEditLanesDrawerHeader from './AddEditLanesDrawerHeader';
import {
  Button,
  Icon,
  SelectInput,
  MultiSelectInput,
  Spinner,
  styled,
} from 'shamrock-clover-ui';
import { AddEditLanesProps } from 'features/carrierCapacity/CapacityTypes';
import SearchLocation from '../SearchLocationsInputs';
import { CarrierCapacityContext } from 'features/carrierCapacity/context/CarrierCapacityContext';
import {
  buildStatesObj,
  trailers,
} from 'features/carrierCapacity/utils/capacityUtils';
import { ActionButtons, EditActionButtons } from './ActionButtons';

const SpinnerContainer = styled.div`
  margin-top: 300px;
`;

const AddEditLanesDrawer: FC<AddEditLanesProps> = ({
  companyInfo,
  open,
  onClose,
  editLane,
}) => {
  const {
    laneAndCapacityForm,
    addCapacity,
    disable,
    isEdited,
    isLoading,
    handleOptionSelected,
    handleSelectedTrailers,
    handleAddCapacity,
    handleDeleteCapacity,
    handleShowDialog,
    handleOnCancelDrawer,
    handleOnAddLaneAndCapacity,
    updateLanesAndCapacity,
  } = useContext(CarrierCapacityContext);
  return (
    <CustomDrawer
      open={open}
      onClose={onClose}
      headerSeparatorType="none"
      width={'883'}
      overlay={true}
      className="custom-drawer"
      drawerHeaderContent={
        <AddEditLanesDrawerHeader
          companyInfo={companyInfo}
          actionButtons={
            editLane ? (
              <EditActionButtons
                disable={disable}
                addCapacity={addCapacity}
                companyInfo={companyInfo}
                handleOnShowDialog={handleShowDialog}
                handleOnCancel={handleOnCancelDrawer}
                handleOnClick={updateLanesAndCapacity}
              />
            ) : (
              <ActionButtons
                disable={disable}
                handleOnCancel={handleOnCancelDrawer}
                handleOnClick={handleOnAddLaneAndCapacity}
                companyInfo={companyInfo}
              />
            )
          }
          title={editLane ? 'Editing Lane' : 'Adding Lane'}
        />
      }
    >
      <ContentContainer>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner size="100" />
          </SpinnerContainer>
        ) : (
          <AddLaneForm>
            <div>
              <span style={{ fontSize: '24px', fontWeight: 600 }}>
                Lane Details
              </span>
              <AddLaneAndCapacityContent>
                <InputsTitles>Pickup</InputsTitles>

                <InputsGroup>
                  <SelectInput
                    options={
                      laneAndCapacityForm.delivery?.optionSelected
                        ?.optionName === 'State'
                        ? [{ option: 'City', optionName: 'City or Zip' }]
                        : [
                            { option: 'City', optionName: 'City or Zip' },
                            { option: 'State', optionName: 'State' },
                          ]
                    }
                    value={laneAndCapacityForm.pickup?.optionSelected}
                    variant="filled"
                    onOptionSelected={(option) =>
                      handleOptionSelected('pickup', true, option!)
                    }
                    width={133}
                  />
                  {laneAndCapacityForm.pickup?.optionSelected?.optionName ===
                  'City or Zip' ? (
                    <SearchLocation name="locationPickup" />
                  ) : (
                    <SelectInputContainer
                      isEmptyState={
                        laneAndCapacityForm.locationPickup?.optionSelected
                          ?.optionName === '' && true
                      }
                    >
                      <SelectInput
                        variant="filled"
                        label="Location"
                        width={255}
                        value={
                          laneAndCapacityForm.locationPickup
                            ?.optionSelected || {
                            optionName: '',
                          }
                        }
                        options={buildStatesObj}
                        onOptionSelected={(option) =>
                          handleOptionSelected('locationPickup', true, option!)
                        }
                      />
                    </SelectInputContainer>
                  )}
                </InputsGroup>
              </AddLaneAndCapacityContent>
              <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                <InputsTitles>Delivery</InputsTitles>
                <InputsGroup>
                  <SelectInput
                    options={
                      laneAndCapacityForm.pickup?.optionSelected?.optionName ===
                      'State'
                        ? [{ option: 'City', optionName: 'City or Zip' }]
                        : [
                            { option: 'City', optionName: 'City or Zip' },
                            { option: 'State', optionName: 'State' },
                          ]
                    }
                    value={laneAndCapacityForm.delivery?.optionSelected}
                    variant="filled"
                    onOptionSelected={(option) =>
                      handleOptionSelected('delivery', true, option!)
                    }
                    width={133}
                  />
                  {laneAndCapacityForm.delivery?.optionSelected?.optionName ===
                  'City or Zip' ? (
                    <SearchLocation name="locationDelivery" />
                  ) : (
                    <SelectInputContainer
                      isEmptyState={
                        laneAndCapacityForm.locationDelivery?.optionSelected
                          ?.optionName === '' && true
                      }
                    >
                      <SelectInput
                        variant="filled"
                        width={255}
                        label="Location"
                        value={
                          laneAndCapacityForm.locationDelivery
                            ?.optionSelected || {
                            optionName: '',
                          }
                        }
                        options={buildStatesObj}
                        onOptionSelected={(option) =>
                          handleOptionSelected(
                            'locationDelivery',
                            true,
                            option!,
                          )
                        }
                      />
                    </SelectInputContainer>
                  )}
                </InputsGroup>
              </div>
              <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                <InputsTitles>Equipment</InputsTitles>
                <InputsGroup>
                  <MultiSelectInput
                    options={
                      laneAndCapacityForm.trailers?.multiSelect! || trailers
                    }
                    label="Trailers"
                    variant="filled"
                    width={394}
                    onOptionSelected={(optionText, result) =>
                      handleSelectedTrailers(optionText, result)
                    }
                  />
                </InputsGroup>
              </div>
              <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                <InputsTitles>Manage Capacity</InputsTitles>
                {addCapacity && (
                  <div style={{ marginTop: '10px' }}>
                    <Button
                      icon={<Icon icon="deleteIcon" color="red" size="16" />}
                      buttonStyle="outlined"
                      isRounded={true}
                      color="red"
                      onClick={handleDeleteCapacity}
                    >
                      Delete Capacity
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div style={{ width: '95%', borderLeft: '1px solid #cccccc' }}>
              <span
                style={{
                  fontSize: '24px',
                  fontWeight: 600,
                  paddingLeft: '16px',
                }}
              >
                Capacity Preferences
              </span>
              {addCapacity ? (
                <AddCapacityForm />
              ) : (
                <CapacityForm
                  onClick={() => handleAddCapacity()}
                  disabled={disable}
                  isEdited={isEdited}
                />
              )}
            </div>
          </AddLaneForm>
        )}
      </ContentContainer>
    </CustomDrawer>
  );
};

export default AddEditLanesDrawer;
