import { FC } from 'react';
import { CompanyDetailsProps } from '../../CapacityTypes';
import useSnackbar from 'hooks/useSnackbar';
import { formatDate } from 'utils/dateUtils';
import {
  ColumnContainer,
  DetailsContainer,
  HeaderText,
  InfoColumn,
  InfoContainer,
  KeysText,
  QuickClipboardCopy,
  ValuesText,
} from './DrawerStyles';

const CompanyDetails: FC<CompanyDetailsProps> = ({ data }) => {
  const { carrier } = data;
  const { showSnackbar } = useSnackbar();

  const handleCopyClick = async (text: string) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    }
    showSnackbar({
      message: `Copied to Clipboard`,
      type: 'success',
      duration: 1000,
    });
  };

  return (
    <>
      <DetailsContainer>
        <ColumnContainer
          height="144px"
          width="298px"
          borderRight={true}
          padding="0 16px 28px 0"
        >
          <HeaderText>Company Info</HeaderText>
          <InfoContainer>
            <InfoColumn width="139px" height="107px">
              <KeysText>McLeod ID</KeysText>
              <KeysText>MC</KeysText>
              <KeysText>DOT</KeysText>
              <KeysText>Trucks</KeysText>
              <KeysText marginBottom="0px">McLeod Status</KeysText>
            </InfoColumn>
            <InfoColumn width="159px" height="107px">
              <ValuesText>{carrier.mcleodCarrierId}</ValuesText>
              <ValuesText>{carrier.mcNumber}</ValuesText>
              <ValuesText>{carrier.dotNumber}</ValuesText>
              <ValuesText>{carrier.trucks}</ValuesText>
              <ValuesText color="#CC5F00" marginBottom="0px">
                No Dispatch
              </ValuesText>
            </InfoColumn>
          </InfoContainer>
        </ColumnContainer>
        <ColumnContainer
          height="148px"
          width="322px"
          borderRight={true}
          padding="0 16px 24px 16px"
        >
          <HeaderText>Contact</HeaderText>
          <InfoContainer>
            <InfoColumn width="132px" height="111px">
              <KeysText>Contact Name</KeysText>
              <KeysText>Phone Number</KeysText>
              <KeysText>Email</KeysText>
              <KeysText>HQ</KeysText>
              <KeysText marginBottom="0px">Address</KeysText>
            </InfoColumn>
            <InfoColumn width="156px" height="111px">
              <ValuesText>{carrier.contactName}</ValuesText>
              <ValuesText>{carrier.contactPhone}</ValuesText>
              {/* Will we need to consider truncation for long email addresses? */}
              <ValuesText>{carrier.contactEmail}</ValuesText>
              <ValuesText>{carrier.headquartersStateAbbr}</ValuesText>
              {/* Ask is this following value belongs to carrier.headquartersState */}
              <ValuesText marginBottom="0px">
                {carrier.contactAddress}
              </ValuesText>
            </InfoColumn>
          </InfoContainer>
        </ColumnContainer>
        <ColumnContainer
          height="172px"
          width="399px"
          borderRight={false}
          padding="0 0 0 16px"
        >
          <HeaderText>Status</HeaderText>
          <InfoContainer>
            <InfoColumn width="158px" height="89px">
              <KeysText>90 Day Load History</KeysText>
              <KeysText>CAM</KeysText>
              <KeysText>RTS Pro Account</KeysText>
              <KeysText marginBottom="0px">Active Users</KeysText>
            </InfoColumn>
            <InfoColumn width="225px" height="89px">
              <ValuesText>{carrier.oneYearLoadCount}</ValuesText>
              <ValuesText>
                <QuickClipboardCopy
                  onClick={() => {
                    handleCopyClick(carrier.camContactEmail);
                  }}
                >
                  {carrier.camContactEmail}
                </QuickClipboardCopy>
              </ValuesText>
              <ValuesText>{carrier.rtsProAccount}</ValuesText>
              <ValuesText marginBottom="0px">
                {carrier.activeUserCount}
              </ValuesText>
            </InfoColumn>
          </InfoContainer>
          <InfoContainer>
            <InfoColumn width="91px" height="38px">
              <KeysText marginBottom="0px">Last Update:</KeysText>
            </InfoColumn>
            <InfoColumn width="308px" height="38px">
              <KeysText marginBottom="0px">{carrier.lastUpdatedBy}</KeysText>
              <KeysText>{formatDate(carrier.lastUpdatedAt)}</KeysText>
            </InfoColumn>
          </InfoContainer>
        </ColumnContainer>
      </DetailsContainer>
    </>
  );
};

export default CompanyDetails;
