import { FC } from 'react';
import { styled, Button, Icon } from 'shamrock-clover-ui';
import { useSearchCapacityQueryParams } from 'hooks/useQueryParams';

const StyledButton = styled(Button)`
  margin-top: 18px;
`;

const SearchCapacityButton: FC<{ handleOnClick: () => void }> = ({
  handleOnClick,
}) => {
  const {
    queryParams: {
      pickupCity,
      pickupState,
      deliveryCity,
      deliveryState,
      equipmentType,
    },
  } = useSearchCapacityQueryParams();

  return (
    <StyledButton
      width="64px"
      height="36px"
      isRounded={true}
      disabled={
        !(
          pickupCity &&
          pickupState &&
          deliveryCity &&
          deliveryState &&
          equipmentType
        )
      }
      onClick={handleOnClick}
    >
      <Icon icon="search" color="white" />
    </StyledButton>
  );
};

export default SearchCapacityButton;
