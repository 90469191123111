import { styled, SmrTable } from 'shamrock-clover-ui';
import { Column, Row } from 'components/cloverTable/TableTypes';

const TABLE_COLUMNS: Column[] = [
  {
    key: 'orderId',
    label: 'Order ID',
    isSortable: false,
    width: 10.7,
  },
  {
    key: 'pickupDate',
    label: 'Pickup Date',
    isSortable: false,
    width: 11.8,
  },
  {
    key: 'customer',
    label: 'Customer',
    isSortable: false,
    width: 17.1,
  },
  {
    key: 'equipment',
    label: 'Equipment',
    isSortable: false,
    width: 11.8,
  },
  {
    key: 'origin',
    label: 'Origin',
    isSortable: false,
    width: 15,
  },
  {
    key: 'destination',
    label: 'Destination',
    isSortable: false,
    width: 15,
  },
  {
    key: 'miles',
    label: 'Miles',
    isSortable: false,
    width: 8.1,
  },
  {
    key: 'customerRate',
    label: 'Customer Rate',
    isSortable: false,
    width: 10.9,
  },
];

const STATIC_TABLE_DATA: Row[] = [
  {
    cells: [
      { value: '000001', content: '000001' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$2,400.25', content: '$2,400.25' },
    ],
  },
  {
    cells: [
      { value: '000002', content: '000002' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '50', content: '50' },
      { value: '$1,300.25', content: '$1,300.25' },
    ],
  },
  {
    cells: [
      { value: '000003', content: '000003' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '42', content: '42' },
      { value: '$800.00', content: '$800.00' },
    ],
  },
  {
    cells: [
      { value: '000004', content: '000004' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000005', content: '000005' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000006', content: '000006' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000007', content: '000007' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000008', content: '000008' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000009', content: '000009' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$2,400.25', content: '$2,400.25' },
    ],
  },
  {
    cells: [
      { value: '000010', content: '000010' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '50', content: '50' },
      { value: '$1,300.25', content: '$1,300.25' },
    ],
  },
  {
    cells: [
      { value: '000011', content: '000011' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '42', content: '42' },
      { value: '$800.00', content: '$800.00' },
    ],
  },
  {
    cells: [
      { value: '000012', content: '000012' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000013', content: '000013' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000014', content: '000014' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000015', content: '000015' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$2,400.25', content: '$2,400.25' },
    ],
  },
  {
    cells: [
      { value: '000016', content: '000016' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '50', content: '50' },
      { value: '$1,300.25', content: '$1,300.25' },
    ],
  },
  {
    cells: [
      { value: '000017', content: '000017' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '42', content: '42' },
      { value: '$800.00', content: '$800.00' },
    ],
  },
  {
    cells: [
      { value: '000018', content: '000018' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000019', content: '000019' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000020', content: '000020' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000021', content: '000021' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$2,400.25', content: '$2,400.25' },
    ],
  },
  {
    cells: [
      { value: '000022', content: '000022' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '50', content: '50' },
      { value: '$1,300.25', content: '$1,300.25' },
    ],
  },
  {
    cells: [
      { value: '000023', content: '000023' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '42', content: '42' },
      { value: '$800.00', content: '$800.00' },
    ],
  },
  {
    cells: [
      { value: '000024', content: '000024' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000025', content: '000025' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
  {
    cells: [
      { value: '000026', content: '000026' },
      { value: '05/12/22', content: '05/12/22' },
      { value: 'JOHN&CO', content: 'JOHN&CO' },
      { value: 'FLAT', content: 'FLAT' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: 'Kansas City, MO', content: 'Kansas City, MO' },
      { value: '27', content: '27' },
      { value: '$3,000.00', content: '$3,000.00' },
    ],
  },
];

const DetailsContainer = styled.div`
  width: 100%;
  background: #eeeeee;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 16px;
  flex-grow: 1;
  box-sizing: border-box;
  gap: 16px;
  box-shadow:
    0px 1px 3px 0px #00000033,
    0px 2px 1px 0px #0000001F,
    0px 1px 1px 0px #00000024;
`;

const TableContainer = styled.div`
  width: 100%;
  table {
    border: hidden;
    border-bottom: 1px solid #cccccc;
  }
  thead {
    position: sticky;
    top: 0;
    th {
      span {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
  tbody {
    font-size: 14px;
    td {
      font-size: 14px;
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
`;

const TableHeader = styled.div`
  height: 37px;
  display: flex;
  align-items: center;
  background: transparent;
  width: 100%;
  strong {
    font-size: 24px;
    font-weight: 500;
    margin-right: 10px;
  }
  font-size: 20px;
  font-weight: 300;
`;

export const CarrierLoadHistory = () => {
  // TOOD: replace static data with real API results
  const rows = STATIC_TABLE_DATA;

  return (
    <>
      <DetailsContainer>
        <TableHeader>
          <strong>90 Day History:</strong> {rows.length} Results
        </TableHeader>
        <TableContainer id="smr-table-container">
          <SmrTable
            columns={TABLE_COLUMNS}
            rows={rows}
            footerOptions={{
              rowsPerPage: 10,
              page: 1,
              totalRowsDisplay: 'rows',
              rowsPerPageSelector: true,
              height: 40,
            }}
          />
        </TableContainer>
      </DetailsContainer>
    </>
  );
};
