import { Button, Icon, Snackbar } from 'shamrock-clover-ui';
import {
  TextContainer,
  ViewText,
  CapacitySearchContainer,
  Container,
  ToolbarContainer,
  StyledInput,
} from 'features/carrierCapacity/CarrierCapacityStyles';
import { useContext, useEffect, useState } from 'react';
import CarrierSearchResults from './components/CarrierSearchResults';
import { CarrierCapacityContext } from './context/CarrierCapacityContext';

const Carriers = () => {
  const [value, setValue] = useState('');
  const MIN_SEARCH_LENGTH = 3;
  const isDisabled = value.length < MIN_SEARCH_LENGTH;

  const showSnackbar = value.length > 0 && value.length < MIN_SEARCH_LENGTH;

  const {
    carrierSearchResultsState,
    searchCarriers,
    resetSearchBar,
    cleanLanesAndCapacityState,
  } = useContext(CarrierCapacityContext);

  useEffect(() => {
    // Clean laneAndCapacity state if this is full
    cleanLanesAndCapacityState();
    // eslint-disable-next-line
  }, []);
  const submitSearch = () => {
    searchCarriers(value);
  };

  return (
    <Container>
      <CapacitySearchContainer>
        <ToolbarContainer>
          <StyledInput
            placeholder="Search by MC#, DOT#, Company, or Contact"
            height="36px"
            width="350px"
            clearSearch={resetSearchBar}
            value={value}
            onKeyDown={(e) =>
              e.key === 'Enter' && !isDisabled && submitSearch()
            }
            onChange={(e) => setValue((e.target as HTMLInputElement).value)}
          />
          <Button
            width="64px"
            height="36px"
            isRounded={true}
            disabled={isDisabled}
            onClick={submitSearch}
          >
            <Icon icon="search" color="white" />
          </Button>
        </ToolbarContainer>
        {carrierSearchResultsState === 'initialView' ? (
          <TextContainer>
            <ViewText>Search for a carrier</ViewText>
          </TextContainer>
        ) : (
          <CarrierSearchResults />
        )}
        <Snackbar
          duration={1000000}
          message="Enter a minimum of 3 characters to search"
          open={showSnackbar}
          variant="error"
        />
      </CapacitySearchContainer>
    </Container>
  );
};

export default Carriers;
